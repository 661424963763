//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-784:_772,_645,_896,_204,_448,_376,_744,_296;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-784')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-784', "_772,_645,_896,_204,_448,_376,_744,_296");
        }
      }catch (ex) {
        console.error(ex);
      }